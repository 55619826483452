import {
  Text,
  Box,
  useMantineTheme,
  Menu,
  UnstyledButton,
  Flex,
  MediaQuery,
  Avatar,
} from "@mantine/core";
import { FiLogOut } from "react-icons/fi";
import { useAuth } from "../../context";

export function User() {
  const theme = useMantineTheme();
  const { logout, userInfo } = useAuth();

  return (
    <Menu position="bottom-end">
      <Box>
        <Menu.Target>
          <UnstyledButton
            sx={{
              display: "block",
              padding: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.black,

              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : theme.colors.gray[0],
              },
            }}
          >
            <Flex direction="row" align="center" gap="md" justify="flex-end">
              {userInfo?.first_name && userInfo?.last_name && (
                <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                  <Flex direction="row" align="center" gap="sm">
                    <Text size="lg" color={theme.colors.dark[6]}>
                      {userInfo.first_name} {userInfo.last_name}
                    </Text>
                    <Avatar radius="xl" />
                  </Flex>
                </MediaQuery>
              )}
            </Flex>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown sx={() => ({ marginLeft: "-10px" })}>
          <Menu.Item onClick={logout} icon={<FiLogOut size={14} />}>
            Cerrar sesión
          </Menu.Item>
        </Menu.Dropdown>
      </Box>
    </Menu>
  );
}
