import { useCookies } from "react-cookie";
import { EmpresaCliente } from "../types";

export const useUsuarioCookie = () => {
  const [cookies, setCookie] = useCookies(["usuario"]);

  const usuarioCookie: EmpresaCliente | null = cookies?.usuario;

  const setUsuarioCookie = (usuario: EmpresaCliente) => {
    setCookie("usuario", usuario, { path: "/" });
  };

  return { usuarioCookie, setUsuarioCookie };
};
