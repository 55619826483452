import {
  Button,
  FileButton,
  Group,
  HoverCard,
  Stack,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import {
  CustomLink,
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabPageSkeleton,
} from "../../../components";
import { useEffect, useState } from "react";
import { TWebinarEnrollment } from "../../../types";
import { adminService } from "../../../api";
import { FaCloudUploadAlt } from "react-icons/fa";
import { showNotification } from "@mantine/notifications";

export const WebinarsPage = () => {
  const [webinarQuery, setWebinarQuery] = useState("");
  const [webinarEnrollments, setWebinarEnrollments] = useState<
    TWebinarEnrollment[]
  >(null!);

  const getWebinarEnrollments = async () => {
    const response = await adminService.webinars.getEnrollments(webinarQuery);
    setWebinarEnrollments(response.data);
  };

  useEffect(() => {
    getWebinarEnrollments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webinarQuery]);

  const uploadMaterial = async (file: File, webinarEnrollmentId: string) => {
    await adminService.webinars.uploadMaterial(webinarEnrollmentId, file);
  };

  const uploadAttendees = async (file: File, webinarEnrollmentId: string) => {
    await adminService.webinars.uploadAttendees(webinarEnrollmentId, file);
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "/webinars", title: "Webinars" }]}
      />
      {!webinarEnrollments ? (
        <GenderLabPageSkeleton />
      ) : (
        <Stack spacing="md">
          <TextInput
            w={400}
            placeholder="Buscar webinars"
            value={webinarQuery}
            onChange={(event) => setWebinarQuery(event.currentTarget.value)}
          />

          <Table>
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Webinar</th>
                <th>Fecha</th>
                <th>Material</th>
                <th>Participantes</th>
              </tr>
            </thead>
            <tbody>
              {webinarEnrollments.map((webinarEnrollment) => (
                <tr key={webinarEnrollment.id}>
                  <td>{webinarEnrollment.client.trade_name}</td>
                  <td>{webinarEnrollment.webinar.title}</td>
                  <td>{webinarEnrollment.webinar.release_date}</td>
                  <td>
                    <HoverCard width={125}>
                      <HoverCard.Target>
                        <div>
                          <FileButton
                            onChange={async (file) => {
                              if (file) {
                                await uploadMaterial(
                                  file,
                                  webinarEnrollment.id
                                );
                                await getWebinarEnrollments();
                                showNotification({
                                  title: "Material subido",
                                  message: "El material ha sido subido",
                                  color: "green",
                                });
                              }
                            }}
                            accept=".zip,.rar,.7zip"
                          >
                            {(props) => (
                              <Button
                                {...props}
                                color="green"
                                variant="outline"
                              >
                                <FaCloudUploadAlt />
                              </Button>
                            )}
                          </FileButton>
                        </div>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Group position="center">
                          {webinarEnrollment.material ? (
                            <>
                              <Text align="center">
                                Ya has subido material.
                              </Text>
                              <CustomLink
                                to={webinarEnrollment.material}
                                target="_blank"
                              >
                                Ver aquí.
                              </CustomLink>
                            </>
                          ) : (
                            "Sin material"
                          )}
                        </Group>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </td>
                  <td>
                    <HoverCard width={150}>
                      <HoverCard.Target>
                        <div>
                          <FileButton
                            onChange={async (file) => {
                              if (file) {
                                await uploadAttendees(
                                  file,
                                  webinarEnrollment.id
                                );
                                await getWebinarEnrollments();
                                showNotification({
                                  title: "Participantes subidos",
                                  message: "Los participantes han sido subidos",
                                  color: "green",
                                });
                              }
                            }}
                            accept=".txt"
                          >
                            {(props) => (
                              <Button
                                {...props}
                                color="green"
                                variant="outline"
                              >
                                <FaCloudUploadAlt />
                              </Button>
                            )}
                          </FileButton>
                        </div>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Group position="center">
                          {webinarEnrollment.attendees ? (
                            <>
                              <Text align="center">
                                Ya has subido participantes.
                              </Text>
                              <CustomLink
                                to={webinarEnrollment.attendees}
                                target="_blank"
                              >
                                Ver aquí.
                              </CustomLink>
                            </>
                          ) : (
                            "Sin participantes"
                          )}
                        </Group>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Stack>
      )}
    </GenderLabAppShell>
  );
};
