import {
  Container,
  PasswordInput,
  TextInput,
  Button,
  Center,
  Grid,
  Card,
  MantineTheme,
  useMantineTheme,
  Flex,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import { GenderLabFooter } from "../../components";
import { ReactComponent as LogoElsa } from "../../static/svg/logo-elsa-bold.svg";
import { useAuth } from "../../context";

export const LoginPage = () => {
  const theme: MantineTheme = useMantineTheme();
  const { login, loading } = useAuth();

  const form = useForm({
    initialValues: {
      // email: "carlos.reategui@genderlab.io",
      // password: "Y1GKld*qb9X00bpd8!G3",
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Ingrese su email"),
      password: (value) => (value ? null : "Ingrese su contraseña"),
    },
  });

  const onSubmit = async () => {
    try {
      await login(form.values.email, form.values.password);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container fluid px={0}>
      <Container
        fluid
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "light"
              ? theme.colors["genderlab-red"][5]
              : theme.colors.gray[3],
          paddingTop: "150px",
          [theme.fn.smallerThan("sm")]: {
            paddingTop: "50px",
          },
        })}
        my={0}
      >
        <Grid py="xl" align="center">
          <Grid.Col span={12} sm={6}>
            <Container size={600}>
              <Flex justify="flex-start">
                <LogoElsa
                  fill={
                    theme.colorScheme === "dark"
                      ? theme.colors["genderlab-red"][4]
                      : "white"
                  }
                />
              </Flex>
            </Container>
          </Grid.Col>
          <Grid.Col span={12} sm={6}>
            <form>
              <Container size={350}>
                <Card
                  shadow="sm"
                  p="lg"
                  radius="md"
                  withBorder
                  style={{ position: "relative" }}
                >
                  <LoadingOverlay visible={loading} overlayBlur={2} />
                  <TextInput
                    type="email"
                    label="Email"
                    placeholder="Ingrese su email"
                    {...form.getInputProps("email")}
                  />
                  <PasswordInput
                    label="Contraseña"
                    placeholder="Ingrese su contraseña"
                    mt="lg"
                    {...form.getInputProps("password")}
                  />
                  <Center>
                    <Button variant="outline" mt="lg" onClick={onSubmit}>
                      Ingresar
                    </Button>
                  </Center>
                </Card>
              </Container>
            </form>
          </Grid.Col>
        </Grid>
      </Container>
      <GenderLabFooter />
    </Container>
  );
};
