import { useForm } from "@mantine/form";
import { Button, Checkbox, Flex, Radio, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Plan, Resource, Contract } from "../../../types";
import { validateStringField } from "../../../utils/validateStringField";
import { adminService } from "../../../api";

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  platformPlans: Plan[];
  resources: Resource[];
  callback: () => void;
  clientId: number;
  contract: Contract | null;
  addOrEdit: "add" | "edit";
};

const ElsaPlatformForm = ({
  loading,
  setLoading,
  platformPlans,
  resources,
  callback,
  clientId,
  contract,
  addOrEdit,
}: Props) => {
  const platformForm = useForm({
    initialValues: {
      plan: contract ? contract.plan?.id.toString() || "" : "",
      customResources:
        contract && contract.plan?.is_custom
          ? contract.plan!.resources.map((resource) => resource.id.toString())
          : [],
      startDate: contract ? new Date(contract.start_date) : null,
      endDate: contract ? new Date(contract.end_date) : null,
      amountPaid: contract ? contract.amount_paid : "",
    },
    validate: {
      plan: validateStringField,
      customResources: (value, values) =>
        (values.plan === "-1" || contract?.plan!.is_custom) &&
        value.length === 0 &&
        "Campo requerido",
      startDate: (value) => value === null && "Campo requerido",
      endDate: (value) => value === null && "Campo requerido",
      amountPaid: validateStringField,
    },
  });

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!platformForm.validate().hasErrors) {
      setLoading(true);

      const startDate = formatDate(platformForm.values.startDate!);
      const endDate = formatDate(platformForm.values.endDate!);
      const amountPaid = parseFloat(platformForm.values.amountPaid);

      if (addOrEdit === "add") {
        // Lógica para agregar un nuevo contrato
        if (platformForm.values.plan === "-1") {
          // Caso para agregar un plan customizado
          await adminService.planContracts
            .customPlanPost({
              client: clientId,
              plan: {
                resources: platformForm.values.customResources.map(
                  (resource: string) => parseInt(resource)
                ),
              },
              start_date: startDate,
              end_date: endDate,
              amount_paid: amountPaid,
            })
            .then(() => {
              callback();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          // Caso para agregar un plan estándar
          await adminService.planContracts
            .post({
              client: clientId,
              plan: parseInt(platformForm.values.plan),
              start_date: startDate,
              end_date: endDate,
              amount_paid: amountPaid,
            })
            .then(() => {
              callback();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } else if (addOrEdit === "edit") {
        // Lógica para editar un contrato existente
        if (contract?.plan?.is_custom) {
          // Caso para editar un plan customizado
          await adminService.planContracts
            .put(contract.id, {
              plan: {
                id: parseInt(platformForm.values.plan),
                resources: platformForm.values.customResources.map(
                  (resource: string) => parseInt(resource)
                ),
              },
              start_date: startDate,
              end_date: endDate,
              amount_paid: amountPaid,
            })
            .then(() => {
              callback();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          // Caso para editar un plan estándar
          await adminService.planContracts
            .put(contract!.id, {
              plan: parseInt(platformForm.values.plan),
              start_date: startDate,
              end_date: endDate,
              amount_paid: amountPaid,
            })
            .then(() => {
              callback();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    } else {
      console.log(platformForm.errors);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap="lg">
        <Radio.Group
          label="Subproducto"
          {...platformForm.getInputProps("plan")}
        >
          {addOrEdit === "add" &&
            platformPlans.map((plan, idx) => (
              <Radio value={plan.id.toString()} label={plan.name} key={idx} />
            ))}

          {addOrEdit === "edit" &&
            (contract?.plan?.is_custom
              ? platformPlans
                  .filter((plan) => plan.id !== -1) // Filtra para mostrar solo los planes no custom
                  .map((plan, idx) => (
                    <Radio
                      value={plan.id.toString()}
                      label={plan.name}
                      key={idx}
                      disabled
                    />
                  ))
                  .concat(
                    <Radio
                      value={contract.plan.id.toString()}
                      label={contract.plan.name}
                      key="custom"
                    />
                  )
              : (() => {
                  // Filtra para no mostrar el plan custom (-1)
                  const existingPlan = platformPlans.find(
                    (plan) => plan.id === contract?.plan!.id
                  );

                  const radios = platformPlans.map((plan, idx) => (
                    <Radio
                      value={plan.id.toString()}
                      label={plan.name}
                      key={idx}
                      disabled={plan.id === -1}
                    />
                  ));

                  // Si el plan actual no existe en platformPlans, añadirlo
                  if (!existingPlan) {
                    radios.unshift(
                      <Radio
                        value={contract?.plan!.id.toString()}
                        label={contract?.plan!.name}
                        key="missing-plan"
                      />
                    );
                  }
                  return radios;
                })())}
        </Radio.Group>
        {(platformForm.values.plan === "-1" ||
          (addOrEdit === "edit" && contract?.plan?.is_custom)) && (
          <Checkbox.Group
            label="Recursos contratados"
            orientation="vertical"
            {...platformForm.getInputProps("customResources")}
          >
            {resources.map((resource, idx) => (
              <Checkbox
                value={resource.id.toString()}
                label={resource.name}
                key={idx}
              />
            ))}
          </Checkbox.Group>
        )}
        <Flex gap="md">
          <TextInput
            label="Monto facturado (USD)"
            placeholder="Monto facturado"
            icon="$"
            type="number"
            {...platformForm.getInputProps("amountPaid")}
          />
          <DatePicker
            label="Fecha de inicio"
            placeholder="Inicio de servicio"
            {...platformForm.getInputProps("startDate")}
          />
          <DatePicker
            label="Fecha de fin"
            placeholder="Fin de servicio"
            {...platformForm.getInputProps("endDate")}
          />
        </Flex>
        <Flex gap="sm" justify="end">
          <Button disabled={loading} type="submit">
            Guardar cambios
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default ElsaPlatformForm;
