import { Text, Card, Divider, Flex, Space, Title, Sx } from "@mantine/core";

type ResultadosPageProps = {
  children?: React.ReactNode;
  title?: string;
  alignSelf?: string;
  description?: string;
  sx?: Sx | (Sx | undefined)[] | undefined;
};

export const ResultadosCard = ({
  title,
  children,
  alignSelf,
  description,
  sx,
}: ResultadosPageProps) => {
  return (
    <Flex sx={{ alignSelf: alignSelf || "stretch", width: "100%", ...sx }}>
      <Card
        withBorder
        p="lg"
        radius="md"
        style={{
          height: "100%",
          width: "100%",
          overflow: "visible",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {title && (
            <>
              <Title order={4}>{title}</Title>
              {description && <Text color="dimmed">{description}</Text>}
              <Card.Section mt="lg">
                <Divider />
              </Card.Section>
              <Space h="xl" />
            </>
          )}
          <div style={{ flex: "0 1 auto" }}>{children}</div>
        </div>
      </Card>
    </Flex>
  );
};
