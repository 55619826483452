import {
  Button,
  Flex,
  Grid,
  Modal,
  Radio,
  TextInput,
  Title,
} from "@mantine/core";
import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  ModalCreateContract,
} from "../../../components";
import { onChangeCleanFields } from "../../../utils/onChangeCleanFields";
import { useForm } from "@mantine/form";
import { useUsuarioCookie } from "../../../cookies";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { validateStringField } from "../../../utils/validateStringField";
import { adminService } from "../../../api";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";

export const EmpresaClientePage = () => {
  const { usuarioCookie } = useUsuarioCookie();
  const [addContractOpened, { open: openContract, close: closeContract }] =
    useDisclosure(false);
  const [addUserOpened, { open: openUser, close: closeUser }] =
    useDisclosure(false);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      informacionDeLaOrganizacion: {
        nombreComercial: usuarioCookie?.trade_name || "",
        razonSocial: usuarioCookie?.business_name || "",
        perteneceAGrupoEconomico: usuarioCookie?.is_in_economic_group
          ? "si"
          : usuarioCookie?.is_in_economic_group === false
          ? "no"
          : "",
        grupoEconomico: usuarioCookie?.economic_group_name || "",
      },
      informacionDeContacto: {
        nombres: usuarioCookie?.contact_name || "",
        apellidos: usuarioCookie?.contact_lastname || "",
        correo: usuarioCookie?.contact_email || "",
      },
      newUser: {
        email: "",
        firstName: "",
        lastName: "",
      },
    },
    validate: {
      newUser: {
        email: validateStringField,
        firstName: validateStringField,
        lastName: validateStringField,
      },
    },
  });

  const addContratacionCallback = () => {
    navigate("/empresas-clientes", {
      state: { fromEmpresaCliente: true },
    });
  };

  const resetNewUser = () => {
    form.setFieldValue("newUser.email", "");
    form.setFieldValue("newUser.firstName", "");
    form.setFieldValue("newUser.lastName", "");
  };

  const addUser = async () => {
    setLoading(true);
    try {
      form.validateField("newUser.email");
      form.validateField("newUser.firstName");
      form.validateField("newUser.lastName");
      if (
        form.isValid("newUser.email") &&
        form.isValid("newUser.firstName") &&
        form.isValid("newUser.lastName") &&
        id
      ) {
        console.log(form.errors);
        await adminService.clients.user.post({
          email: form.values.newUser.email,
          first_name: form.values.newUser.firstName,
          last_name: form.values.newUser.lastName,
          client: parseInt(id),
        });
        showNotification({
          title: "Éxito",
          message: "Usuario creado exitosamente.",
          color: "green",
        });
        closeUser();
        resetNewUser();
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        showNotification({
          title: "Error",
          message: "No se pudo crear el usuario.",
          color: "red",
        });
      }
    }
    setLoading(false);
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[
          { link: "/empresas-clientes", title: "Empresas Clientes" },
          { link: "", title: usuarioCookie?.trade_name || "" },
        ]}
      />

      {id && (
        <ModalCreateContract
          addOrEdit="add"
          addContractOpened={addContractOpened}
          closeContract={closeContract}
          clientId={parseInt(id)}
          callback={addContratacionCallback}
          contract={null}
        />
      )}

      <Modal
        opened={addUserOpened}
        onClose={() => {
          closeUser();
          resetNewUser();
        }}
        title="Añadir usuario"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addUser();
          }}
        >
          <Flex gap="sm" direction="column">
            <TextInput
              label="Correo electrónico"
              disabled={loading}
              {...form.getInputProps("newUser.email")}
            />
            <TextInput
              label="Nombres"
              disabled={loading}
              {...form.getInputProps("newUser.firstName")}
            />
            <TextInput
              label="Apellidos"
              disabled={loading}
              {...form.getInputProps("newUser.lastName")}
            />
            <Flex gap="sm" mt="md" justify="center">
              <Button fullWidth disabled={loading} type="submit">
                Añadir usuario
              </Button>
            </Flex>
          </Flex>
        </form>
      </Modal>

      <Grid>
        <Grid.Col span={12} md={6}>
          <TextInput
            label="Razón social"
            disabled={loading}
            {...form.getInputProps("informacionDeLaOrganizacion.razonSocial")}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            label="Nombre comercial"
            disabled={loading}
            {...form.getInputProps(
              "informacionDeLaOrganizacion.nombreComercial"
            )}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Title order={4}>Información de contacto</Title>
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            label="Nombres"
            disabled={loading}
            {...form.getInputProps("informacionDeContacto.nombres")}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            label="Apellidos"
            disabled={loading}
            {...form.getInputProps("informacionDeContacto.apellidos")}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            label="Correo electrónico"
            disabled={loading}
            {...form.getInputProps("informacionDeContacto.correo")}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Radio.Group
            label="¿La organización pertenece a algún grupo económico?"
            {...form.getInputProps(
              "informacionDeLaOrganizacion.perteneceAGrupoEconomico"
            )}
            onChange={(value) =>
              onChangeCleanFields(
                value,
                "informacionDeLaOrganizacion.perteneceAGrupoEconomico",
                ["informacionDeLaOrganizacion.grupoEconomico"],
                "no",
                form
              )
            }
          >
            <Radio value="si" label="Sí" />
            <Radio value="no" label="No" />
          </Radio.Group>
        </Grid.Col>
        {form.values.informacionDeLaOrganizacion.perteneceAGrupoEconomico ===
          "si" && (
          <Grid.Col span={12} md={6}>
            <TextInput
              disabled={
                form.values.informacionDeLaOrganizacion
                  .perteneceAGrupoEconomico !== "si" || loading
              }
              label="Nombre del grupo económico"
              {...form.getInputProps(
                "informacionDeLaOrganizacion.grupoEconomico"
              )}
            />
          </Grid.Col>
        )}
      </Grid>
      <Flex gap="sm" mt="md" justify="center">
        <Button onClick={openUser}>Añadir usuario</Button>
        <Button onClick={openContract}>Añadir contratación</Button>
        <Button disabled={true}>Guardar</Button>
      </Flex>
    </GenderLabAppShell>
  );
};
