import React, { CSSProperties, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Image,
  ScrollArea,
  Flex,
  Grid,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import { User } from "./_user";
import { MainLinks } from "./_mainLinks";
import { useNavigate } from "react-router-dom";

interface MyComponentStyles extends CSSProperties {
  fullScreen: boolean;
}

const useStyles = createStyles((theme, { fullScreen }: MyComponentStyles) => ({
  root: {
    padding: "20px 20px",
    maxWidth: fullScreen ? "2500px" : "1600px",
    margin: "0 auto",

    "@media (max-width: 1024px)": {
      padding: "10px 10px",
    },
  },
}));

type Props = {
  children: React.ReactNode;
  fullScreen?: boolean;
};

export const GenderLabAppShell: React.FC<Props> = ({
  children,
  fullScreen = false,
}) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ fullScreen });

  const navigate = useNavigate();

  return (
    <AppShell
      padding="md"
      navbarOffsetBreakpoint="md"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="md"
          hidden={!opened}
          width={{ base: 250 }}
        >
          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            <MainLinks />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={{ base: 90 }} p="md">
          <Grid justify="space-between" align="center">
            <Grid.Col span="content">
              <Flex align="center" gap="0" justify="flex-start">
                <MediaQuery largerThan="md" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="sm"
                  />
                </MediaQuery>
                <UnstyledButton
                  component="a"
                  href="#"
                  onClick={() => navigate("/inicio")}
                >
                  <Image
                    src="/img/elsa-light.png"
                    width={130}
                    style={{ marginLeft: "10px" }}
                  />
                </UnstyledButton>
              </Flex>
            </Grid.Col>

            <Grid.Col span="content">
              <Flex justify="flex-end">
                <User />
              </Flex>
            </Grid.Col>
          </Grid>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colors.gray[0],
        },
      })}
    >
      <div className={classes.root}>{children}</div>
    </AppShell>
  );
};
