import { Card, Text, createStyles } from "@mantine/core";
import { EmpresaCliente } from "../../../types";
import { useNavigate } from "react-router-dom";
import { useUsuarioCookie } from "../../../cookies";

const useStyles = createStyles((theme) => ({
  card: {
    ":hover": {
      cursor: "pointer",
    },
  },
}));

type Props = {
  usuario: EmpresaCliente;
};

export const UsuarioCard = ({ usuario }: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { setUsuarioCookie } = useUsuarioCookie();

  const onClick = () => {
    setUsuarioCookie(usuario);
    navigate(`/empresas-clientes/${usuario.id}`);
  };

  return (
    <Card p="md" radius="md" className={classes.card} onClick={onClick}>
      <Text fw={500} fz={20} align="center">
        {usuario.trade_name}
      </Text>
      <Text color="dimmed" align="center">
        {usuario.contact_name} {usuario.contact_lastname} -{" "}
        {usuario.contact_email}
      </Text>
    </Card>
  );
};
