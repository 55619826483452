import {
  Center,
  Pagination,
  Table,
  Tabs,
  TextInput,
} from "@mantine/core";
import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabPageSkeleton,
} from "../../components";
import { useEffect, useState } from "react";
import { adminService } from "../../api";
import { TMediciones, TMedicionesTableData, TStaffSurvey } from "../../types";
import { useDisclosure } from "@mantine/hooks";
import { EditButton } from "../../components/EditDeleteButtonsCombo/EditButton";
import { ModalEditStaffSurvey } from "../../components/StaffSurvey";

export const MedicionesPage = () => {
  const [measurementProcesses, setMeasurementProcesses] = useState<TMedicionesTableData>(null!);

  const [clientesQuery, setClientesQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState<string | null>(
    "mediciones-lanzadas"
  );
  const [staffSurveyToEdit, setStaffSurveyToEdit] = useState<TStaffSurvey | null>(null);
  const [editStaffSurveyOpened, { open: openStaffSurvey, close: closeStaffSurvey }] =
    useDisclosure(false);

  const pageSize = 10;
  const [page, setPage] = useState(1);

  useEffect(() => {
    const getPaginatedData = async () => {
      const response = await adminService.measurementProcess.get(
        page,
        pageSize,
      );
      setMeasurementProcesses(response.data);
    };

    getPaginatedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const getPaginatedData = async () => {
      setPage(1);
      const response = await adminService.measurementProcess.get(
        1,
        pageSize,
        clientesQuery,
      );
      setMeasurementProcesses(response.data);
    };
    getPaginatedData();
  }, [clientesQuery]);

  useEffect(() => {
    const getPaginatedData = async () => {
      setPage(1);
      const response = await adminService.measurementProcess.get(
        1,
        pageSize,
      );
      setMeasurementProcesses(response.data);
    };
    getPaginatedData();
  }, []);

  const onEditClick = (row: TMediciones) => {
    setStaffSurveyToEdit(row.staff_survey);
    openStaffSurvey();
  };

  const editStaffSurveyCallback = () => {
    setMeasurementProcesses(null!);
    const getPaginatedData = async () => {
      const response = await adminService.measurementProcess.get(
        page,
        pageSize,
      );
      setMeasurementProcesses(response.data);
    };
    getPaginatedData();
    closeStaffSurvey();
    setSelectedTab("mediciones-lanzadas");
  };
    

  const formatDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year.length === 2 ? `20${year}` : year}`;
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "", title: "Mediciones" }]}
      />

      <ModalEditStaffSurvey
        editStaffSurveyOpened={editStaffSurveyOpened}
        closeStaffSurvey={closeStaffSurvey}
        callback={editStaffSurveyCallback}
        staffSurvey={staffSurveyToEdit}
      />
      
      <Tabs value={selectedTab} onTabChange={setSelectedTab}>
        <Tabs.List>
          <Tabs.Tab value="mediciones-lanzadas">
            Mediciones lanzadas
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="mediciones-lanzadas" pt="xs">
          <TextInput
            w={400}
            placeholder="Buscar por cliente"
            value={clientesQuery}
            onChange={(e) => setClientesQuery(e.target.value)}
          />
          {!measurementProcesses ? (
            <GenderLabPageSkeleton />
          ) : (
            <>
              <Table
                striped
                highlightOnHover
                withBorder
                withColumnBorders
                mt="md"
              >
                <thead>
                  <tr>
                    <th>Título</th>
                    <th>Nombre Comercial</th>
                    <th>Fecha de lanzamiento</th>
                    <th>Fecha de finalización</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {measurementProcesses.data
                    .map((measurementProcess, idx) => (
                      <tr key={idx}>
                        <td>{measurementProcess.title}</td>
                        <td>{measurementProcess.trade_name}</td>
                        <td>{formatDate(measurementProcess.staff_survey.launched_at)}</td>
                        <td>{formatDate(measurementProcess.staff_survey.ended_at)}</td>
                        <td>
                          <EditButton onClick={(): void => onEditClick(measurementProcess)} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Center>
                <Pagination
                  mt="md"
                  total={measurementProcesses.total_pages}
                  onChange={setPage}
                  color="blue"
                />
              </Center>
            </>
          )}
        </Tabs.Panel>
      </Tabs>
    </GenderLabAppShell>
  );
};
