import { useForm } from "@mantine/form";
import { Button, Flex, TextInput, Loader, Select } from "@mantine/core";
import { SingleUseService, Contract } from "../../../types";
import { adminService } from "../../../api";
import { useState } from "react";
import { validateStringField } from "../../../utils/validateStringField";

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  singleUseServices: SingleUseService[];
  setSingleUseServices: (services: SingleUseService[]) => void;
  callback: () => void;
  clientId: number;
  contract: Contract | null;
  addOrEdit: "add" | "edit";
};

const OtherProductForm = ({
  loading,
  setLoading,
  singleUseServices,
  setSingleUseServices,
  callback,
  clientId,
  contract,
  addOrEdit,
}: Props) => {
  const [creatingService, setCreatingService] = useState<boolean>(false);

  const otherProductForm = useForm({
    initialValues: {
      subproduct: contract
        ? contract.single_use_service?.id.toString() || ""
        : "",
      amountPaid: contract ? contract.amount_paid : "",
    },
    validate: {
      subproduct: validateStringField,
      amountPaid: validateStringField,
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!otherProductForm.validate().hasErrors) {
      setLoading(true);
      const amountPaid = parseFloat(otherProductForm.values.amountPaid);
      if (addOrEdit === "add") {
        adminService.singleUseServicesContracts
          .post({
            client: clientId,
            single_use_service: parseInt(otherProductForm.values.subproduct),
            amount_paid: amountPaid,
          })
          .then(() => {
            callback();
          })
          .catch((error) => {
            console.error("Error creating new service contract:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (addOrEdit === "edit") {
        adminService.singleUseServicesContracts
          .put(contract!.id, {
            client: clientId,
            single_use_service: parseInt(otherProductForm.values.subproduct),
            amount_paid: amountPaid,
          })
          .then(() => {
            callback();
          })
          .catch((error) => {
            console.error("Error updating service contract:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      console.log(otherProductForm.errors);
    }
  };

  const handleCreateService = (value: string) => {
    setCreatingService(true);
    const createNewService = async () => {
      try {
        const response = await adminService.singleUseServices.post({
          name: value,
          category: "Otros",
        });
        const newService: SingleUseService = response.data;
        setSingleUseServices([...singleUseServices, newService]);
        otherProductForm.setFieldValue("subproduct", newService.id.toString());
        return { value: newService.id.toString(), label: newService.name };
      } catch (error) {
        console.error("Error creating new service:", error);
        return null;
      } finally {
        setCreatingService(false);
      }
    };
    createNewService();
    return value;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap="lg">
        <Select
          label="Subproducto"
          data={singleUseServices.map((service) => ({
            value: service.id.toString(),
            label: service.name,
            group: service.category,
          }))}
          dropdownPosition="bottom"
          placeholder="Descripción del subproducto"
          nothingFound="No se encontraron subproductos"
          searchable
          rightSection={creatingService ? <Loader size="xs" /> : null}
          creatable
          getCreateLabel={(value) => `Crear subproducto "${value}"`}
          onCreate={handleCreateService}
          {...otherProductForm.getInputProps("subproduct")}
        />

        <TextInput
          label="Monto facturado (USD)"
          icon="$"
          type="number"
          placeholder="Monto facturado"
          {...otherProductForm.getInputProps("amountPaid")}
        />
        <Flex gap="sm" justify="end">
          <Button disabled={loading} type="submit">
            Guardar cambios
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default OtherProductForm;
