import {
  CustomLink,
  FormErrorText,
  GenderLabAppShell,
  ResultadosCard,
} from "../../components";
import { useEffect, useState } from "react";
import {
  Stepper,
  Button,
  Group,
  FileInput,
  Select,
  Table,
  Text,
  Title,
  MultiSelect,
  List,
  Modal,
  Alert,
  Loader,
  Stack,
} from "@mantine/core";
import { AiOutlineUpload } from "react-icons/ai";
import Papa from "papaparse";
import { useForm } from "@mantine/form";
import { validateStringField } from "../../utils/validateStringField";
import { adminService } from "../../api";
import { EmpresaCliente, TCourse, TStudentUploadResponse } from "../../types";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { IconAlertCircle, IconCheck } from "@tabler/icons";

export const AulaAdminPage = () => {
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const [clientValue, onSearchChange] = useState("");
  const [parsedCsvFile, setParsedCsvFile] = useState<Papa.ParseResult<{
    nombre: string;
    email: string;
    puesto: string;
    area: string;
    centro: string;
  }> | null>(null);
  const [csvFileName, setCsvFileName] = useState<string | null>(null);
  const [csvFileProcessed, setCsvFileProcessed] = useState(false);

  const [fieldsFound, setFieldsFound] = useState<{
    email: boolean;
    document: boolean;
    name: boolean;
    job: boolean;
    area: boolean;
    workplace: boolean;
  }>({
    email: false,
    document: false,
    name: false,
    job: false,
    area: false,
    workplace: false,
  });

  const [clientDropdownData, setClientDropdownData] = useState<
    { value: string; label: string }[]
  >([]);
  const [coursesDropdownData, setCoursesDropdownData] = useState<
    { value: string; label: string }[]
  >([]);

  const form = useForm({
    initialValues: {
      searchValue: "",
      csvFile: new File([], ""),
      courses: [],
    },
    validate: {
      searchValue: (value) => validateStringField(value),
      csvFile: (value) => value === null && "Debes subir un archivo.",
      courses: (value) =>
        value.length === 0 && "Debes seleccionar al menos un curso.",
    },
  });

  const navigate = useNavigate();

  const [
    uploadCsvModaledOpened,
    { open: openUploadCsvModal, close: closeUploadCsvModal },
  ] = useDisclosure(false);

  const [
    uploadResultsModaledOpened,
    { open: openUploadResultsModal, close: closeUploadResultsModal },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(false);

  const [uploadExitoso, setUploadExitoso] = useState(false);
  const [uploadResults, setUploadResults] =
    useState<TStudentUploadResponse | null>(null);

  useEffect(() => {
    const getAndSetClients = async () => {
      const response = await adminService.clients.get();
      const clients: EmpresaCliente[] = response.data;
      setClientDropdownData(
        clients.map((client) => ({
          value: client.id.toString(),
          label: `${client.business_name} - ${client.trade_name}`,
        }))
      );
    };

    const getAndSetCourses = async () => {
      const response = await adminService.courses.get();
      const courses: TCourse[] = response.data;
      setCoursesDropdownData(
        courses.map((course) => ({
          value: course.id.toString(),
          label: course.name,
        }))
      );
    };

    getAndSetClients();
    getAndSetCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!csvFileProcessed && form.values.csvFile.name !== "") {
      setCsvFileProcessed(true);
      const csvFile: File = form.values.csvFile;
      setCsvFileName(csvFile.name);

      csvFile.text().then((text) => {
        const parsed: Papa.ParseResult<{
          nombre: string;
          email: string;
          puesto: string;
          area: string;
          centro: string;
        }> = Papa.parse(text, {
          header: true,
          delimiter: "",
          transformHeader: (header) => {
            const transformedHeader = header.replace(/\s/g, "");
            return transformedHeader;
          },
        });
        setParsedCsvFile(parsed);

        const csv = Papa.unparse(parsed.data, { header: true, delimiter: "," });
        form.setFieldValue("csvFile", new File([csv], csvFile.name));

        const findHeader = (header: string) =>
          parsed.meta.fields?.find((field) => field === header);
        setFieldsFound({
          email: !!findHeader("email"),
          document: !!findHeader("documento"),
          name: !!findHeader("nombre"),
          job: !!findHeader("puesto"),
          area: !!findHeader("area"),
          workplace: !!findHeader("centro"),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.csvFile]);

  const handleNext = () => {
    if (active === 0) {
      if (!form.validateField("searchValue").hasError) {
        nextStep();
      }
    } else if (active === 1) {
      if (
        !form.validateField("csvFile").hasError &&
        Object.values(fieldsFound).every((value) => value)
      ) {
        nextStep();
      }
    } else if (active === 2) {
      if (!form.validateField("courses").hasError) {
        nextStep();
      }
    }
  };

  const uploadCsv = async () => {
    try {
      setLoading(true);
      // console.log(form.values.csvFile);
      const response = await adminService.students.postFromCsv(
        parseInt(form.values.searchValue),
        form.values.courses,
        form.values.csvFile
      );
      setUploadResults(response.data);
      setUploadExitoso(true);
    } catch (error) {
      // console.log(error);
      setUploadExitoso(false);
    } finally {
      closeUploadCsvModal();
      setLoading(false);
      openUploadResultsModal();
    }
  };

  const onCloseUploadResultsModal = () => {
    closeUploadResultsModal();
    navigate(0);
  };

  return (
    <GenderLabAppShell>
      <Title order={2} sx={{ marginBottom: 20 }}>
        Subir estudiantes
      </Title>
      <Stepper active={active} breakpoint="sm">
        <Stepper.Step label="Paso 1" description="Seleccionar empresa cliente">
          <Select
            label="Busca aquí a la empresa cliente"
            searchable
            onSearchChange={onSearchChange}
            searchValue={clientValue}
            nothingFound="No se encontró la empresa cliente"
            data={clientDropdownData}
            withAsterisk
            disabled={clientDropdownData.length === 0}
            {...form.getInputProps("searchValue")}
          />
        </Stepper.Step>
        <Stepper.Step label="Paso 2" description="Subir .csv con estudiantes">
          <Stack spacing="md">
            <FileInput
              label="Sube aquí el archivo .csv con los estudiantes"
              withAsterisk
              accept=".csv"
              icon={<AiOutlineUpload />}
              {...form.getInputProps("csvFile")}
              onChange={(file) => {
                if (file) {
                  form.setFieldValue("csvFile", file);
                  setCsvFileProcessed(false);
                }
              }}
            />
            <Text color="dimmed">
              Puedes encontrar el template{" "}
              <CustomLink
                to="https://docs.google.com/spreadsheets/d/15XuOD1qfL0A0TM3HsRrn2qFkNDTH0DqLixhP0lmEQpI/edit?usp=sharing"
                target="_blank"
                fontSize={16}
              >
                aquí
              </CustomLink>
              .
            </Text>
          </Stack>
        </Stepper.Step>
        <Stepper.Step label="Paso 3" description="Seleccionar cursos">
          <MultiSelect
            label="Selecciona aquí el/los curso/s"
            data={coursesDropdownData}
            withAsterisk
            {...form.getInputProps("courses")}
          />
        </Stepper.Step>
        <Stepper.Completed>
          <ResultadosCard title="Resumen">
            <List>
              <List.Item>
                <Text>
                  <b>Empresa cliente</b>:{" "}
                  {
                    clientDropdownData.find(
                      (client) => client.value === form.values.searchValue
                    )?.label
                  }
                </Text>
              </List.Item>
              <List.Item>
                <Text>
                  <b>Archivo .csv</b>: {csvFileName}
                </Text>
              </List.Item>
              <List.Item>
                <Text>
                  <b>Cursos</b>:{" "}
                  <List>
                    {coursesDropdownData
                      .filter((course) => {
                        const courses: string[] = form.values.courses;
                        return courses.includes(course.value);
                      })
                      .map((course) => (
                        <List.Item key={course.value}>{course.label}</List.Item>
                      ))}
                  </List>
                </Text>
              </List.Item>
            </List>
          </ResultadosCard>
        </Stepper.Completed>
      </Stepper>

      <Group position="center" mt="xl">
        <Button variant="default" onClick={prevStep}>
          Atrás
        </Button>
        {active < 3 ? (
          <Button onClick={handleNext}>Siguiente paso</Button>
        ) : (
          <Button onClick={openUploadCsvModal}>Subir</Button>
        )}
      </Group>

      {active === 1 && parsedCsvFile && (
        <ResultadosCard sx={{ marginTop: 16 }}>
          <Table mt="xs" striped highlightOnHover withBorder withColumnBorders>
            <thead>
              <tr>
                {parsedCsvFile.meta.fields?.map((field, index) => (
                  <th key={index}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {parsedCsvFile.data.slice(0, 5).map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <Text mt="md" fz="xs" color="dimmed">
            Primeras cinco filas del archivo.
          </Text>
          {fieldsFound.email &&
          fieldsFound.document &&
          fieldsFound.name &&
          fieldsFound.job &&
          fieldsFound.area &&
          fieldsFound.workplace ? null : (
            <FormErrorText>
              El encabezado debe tener las columnas{" "}
              <i>email, documento, nombre, puesto, area y centro</i>. Revisa
              también que no hayan espacios en blanco en el encabezado.
            </FormErrorText>
          )}
        </ResultadosCard>
      )}

      <Modal
        opened={uploadCsvModaledOpened}
        onClose={closeUploadCsvModal}
        title="¿Estás seguro/a de que quieres subir estos datos?"
      >
        <Group position="center" mt="xl">
          <Button variant="default" onClick={closeUploadCsvModal}>
            Cancelar
          </Button>
          <Button onClick={uploadCsv} disabled={loading}>
            {!loading ? "Sí" : <Loader size="sm" />}
          </Button>
        </Group>
      </Modal>

      <Modal
        opened={uploadResultsModaledOpened}
        onClose={onCloseUploadResultsModal}
        title="Resultados de la subida"
      >
        {uploadExitoso ? (
          <Alert
            icon={<IconCheck size="1rem" />}
            title="Éxito - estudiantes registrados"
            color="green"
          >
            {uploadResults ? (
              <>
                <List size="sm">
                  <List.Item>
                    Estudiantes nuevos: {uploadResults.new_students}
                  </List.Item>
                  <List.Item>
                    Estudiantes anteriormente registrados:{" "}
                    {uploadResults.old_students}
                  </List.Item>
                </List>
                <Text fw={600} mt="md">
                  Por curso
                </Text>
                <List size="sm" mr="md">
                  {Object.keys(uploadResults.enrollments).map((courseName) => (
                    <List.Item key={courseName}>
                      {courseName}
                      <List withPadding listStyleType="disc" size="sm">
                        <List.Item>
                          Nuevos: {uploadResults.enrollments[courseName].new}
                        </List.Item>
                        <List.Item>
                          Anteriormente registrados:{" "}
                          {uploadResults.enrollments[courseName].old}
                        </List.Item>
                      </List>
                    </List.Item>
                  ))}
                </List>
              </>
            ) : (
              <Text size="sm">
                No hay detalles acerca de los alumnos subidos
              </Text>
            )}
          </Alert>
        ) : (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error"
            color="red"
          >
            Hubo un error al subir los datos. Por favor, ponte en contacto con
            el equipo tech.
          </Alert>
        )}
        <Group position="center" mt="xl">
          <Button onClick={onCloseUploadResultsModal}>Cerrar</Button>
        </Group>
      </Modal>
    </GenderLabAppShell>
  );
};
