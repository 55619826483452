import { useForm } from "@mantine/form";
import { Button, Flex, Radio, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Plan, Contract } from "../../../types";
import { validateStringField } from "../../../utils/validateStringField";
import { adminService } from "../../../api";

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  aulaVirtualPlans: Plan[];
  callback: () => void;
  clientId: number;
  contract: Contract | null;
  addOrEdit: "add" | "edit";
};

const AulaVirtualForm = ({
  loading,
  setLoading,
  aulaVirtualPlans: aulaVirtualPlans,
  callback,
  clientId,
  contract,
  addOrEdit,
}: Props) => {
  const aulaVirtualForm = useForm({
    initialValues: {
      plan: contract ? contract.plan?.id.toString() || "" : "",
      startDate: contract ? new Date(contract.start_date) : null,
      endDate: contract ? new Date(contract.end_date) : null,
      amountPaid: contract ? contract.amount_paid : "",
    },
    validate: {
      plan: validateStringField,
      startDate: (value) => value === null && "Campo requerido",
      endDate: (value) => value === null && "Campo requerido",
      amountPaid: validateStringField,
    },
  });

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!aulaVirtualForm.validate().hasErrors) {
      setLoading(true);

      const startDate = formatDate(aulaVirtualForm.values.startDate!);
      const endDate = formatDate(aulaVirtualForm.values.endDate!);
      const amountPaid = parseFloat(aulaVirtualForm.values.amountPaid);

      if (addOrEdit === "add") {
        adminService.planContracts
          .post({
            client: clientId,
            plan: parseInt(aulaVirtualForm.values.plan),
            start_date: startDate,
            end_date: endDate,
            amount_paid: amountPaid,
          })
          .then(() => {
            callback();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (addOrEdit === "edit") {
        adminService.planContracts
          .put(contract!.id, {
            plan: parseInt(aulaVirtualForm.values.plan),
            start_date: startDate,
            end_date: endDate,
            amount_paid: amountPaid,
          })
          .then(() => {
            callback();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      console.log(aulaVirtualForm.errors);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap="lg">
        <Radio.Group
          label="Subproducto"
          {...aulaVirtualForm.getInputProps("plan")}
        >
          {aulaVirtualPlans.map((plan, idx) => (
            <Radio value={plan.id.toString()} label={plan.name} key={idx} />
          ))}
        </Radio.Group>
        <Flex gap="md">
          <TextInput
            label="Monto facturado (USD)"
            placeholder="Monto facturado"
            icon="$"
            type="number"
            {...aulaVirtualForm.getInputProps("amountPaid")}
          />
          <DatePicker
            label="Fecha de inicio"
            placeholder="Inicio de servicio"
            {...aulaVirtualForm.getInputProps("startDate")}
          />
          <DatePicker
            label="Fecha de fin"
            placeholder="Fin de servicio"
            {...aulaVirtualForm.getInputProps("endDate")}
          />
        </Flex>
        <Flex gap="sm" justify="end">
          <Button disabled={loading} type="submit">
            Guardar cambios
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default AulaVirtualForm;
