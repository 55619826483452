import { Title, Text } from "@mantine/core";
import { GenderLabAppShell } from "../../components";

export const NotFoundPage = () => {
  return (
    <GenderLabAppShell>
      <Title>Página no encontrada</Title>
      <Text>Asegúrese de haber ingresado al link provisto por GenderLab.</Text>
      <Text>Contacte a contacto@genderlab.io para más información.</Text>
    </GenderLabAppShell>
  );
};
