import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context";

type Props = {
  children: React.ReactNode;
};

export const ProtectedRoute = ({ children }: Props) => {
  const { tryToPersistSession, userInfo } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!userInfo) tryToPersistSession();
  }, [tryToPersistSession, navigate, location.pathname, userInfo]);

  return <>{children}</>;
};
