import {
  Badge,
  Button,
  Center,
  Flex,
  Image,
  Modal,
  Pagination,
  Radio,
  Table,
  Tabs,
  TextInput,
  Title,
} from "@mantine/core";
import {
  GenderLabAppShell,
  GenderLabBreadcrumbs,
  GenderLabPageSkeleton,
  ModalCreateContract,
} from "../../components";
import { useEffect, useState } from "react";
import { adminService } from "../../api";
import { ContractTableData, EmpresaCliente, Contract } from "../../types";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { onChangeCleanFields } from "../../utils/onChangeCleanFields";
import { validateStringField } from "../../utils/validateStringField";
import { useLocation, useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { useUsuarioCookie } from "../../cookies";
import Papa from "papaparse";
import { EditDeleteButtonsCombo } from "../../components/EditDeleteButtonsCombo";
import { openConfirmModal } from "@mantine/modals";

export const EmpresasClientesPage = () => {
  const [users, setUsers] = useState<EmpresaCliente[]>(null!);
  const [contractTableData, setContractTableData] =
    useState<ContractTableData | null>(null);
  const [activeUser, setActiveUser] = useState<number>(null!);
  const [addOrEdit, setAddOrEdit] = useState<"add" | "edit">("add");

  const { state } = useLocation();

  const [addOrgOpened, { open: openOrg, close: closeOrg }] =
    useDisclosure(false);
  const [addContractOpened, { open: openContract, close: closeContract }] =
    useDisclosure(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [clientesQuery, setClientesQuery] = useState("");
  const [contratacionesQuery, setContratacionesQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState<string | null>(
    "relacion-de-clientes"
  );
  const [contratoAEditar, setContratoAEditar] = useState<Contract | null>(null);

  const { setUsuarioCookie } = useUsuarioCookie();

  const pageSize = 10;
  const [activePage, setPage] = useState(1);

  useEffect(() => {
    const getPaginatedData = async () => {
      const response = await adminService.contracts.get(
        activePage,
        pageSize,
        contratacionesQuery
      );
      setContractTableData(response.data);
    };

    getPaginatedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  const form = useForm({
    initialValues: {
      informacionDeLaOrganizacion: {
        nombreComercial: "",
        razonSocial: "",
        perteneceAGrupoEconomico: "",
        grupoEconomico: "",
      },
      informacionDeContacto: {
        nombres: "",
        apellidos: "",
        correo: "",
      },
    },

    validate: {
      informacionDeLaOrganizacion: {
        nombreComercial: validateStringField,
        razonSocial: validateStringField,
        perteneceAGrupoEconomico: validateStringField,
        grupoEconomico: (value) => {
          if (
            form.values.informacionDeLaOrganizacion.perteneceAGrupoEconomico ===
            "si"
          ) {
            return validateStringField(value);
          }
        },
      },
      informacionDeContacto: {
        nombres: validateStringField,
        apellidos: validateStringField,
        correo: validateStringField,
      },
    },
  });

  useEffect(() => {
    if (state?.fromEmpresaCliente) {
      setSelectedTab("seguimiento-a-contrataciones");
    }
  }, [state]);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await adminService.clients.get();
      setUsers(response.data);
    };

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOrEditContractCallback = async () => {
    setContractTableData(null!);
    const response = await adminService.contracts.get(
      activePage,
      pageSize,
      contratacionesQuery
    );
    setContractTableData(response.data);
    closeContract();
    setSelectedTab("seguimiento-a-contrataciones");
  };

  useEffect(() => {
    const getPaginatedData = async () => {
      setPage(1);
      const response = await adminService.contracts.get(
        1,
        pageSize,
        contratacionesQuery
      );
      setContractTableData(response.data);
    };
    getPaginatedData();
  }, [contratacionesQuery]);

  const postUser = async () => {
    if (!form.validate().hasErrors) {
      try {
        setLoading(true);
        const response = await adminService.clients.post({
          client: {
            business_name: form.values.informacionDeLaOrganizacion.razonSocial,
            trade_name: form.values.informacionDeLaOrganizacion.nombreComercial,
            contact_name: form.values.informacionDeContacto.nombres,
            contact_lastname: form.values.informacionDeContacto.apellidos,
            contact_email: form.values.informacionDeContacto.correo,
            is_in_economic_group:
              form.values.informacionDeLaOrganizacion
                .perteneceAGrupoEconomico === "si",
            economic_group_name:
              form.values.informacionDeLaOrganizacion.grupoEconomico,
          },
        });

        const client: EmpresaCliente = response.data.client;

        const csvData = Papa.unparse([
          ["username", "password"],
          [response.data.user.email, response.data.user.password],
        ]);
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `credentials_${form.values.informacionDeLaOrganizacion.nombreComercial
          .toLowerCase()
          .replace(/ /g, "_")}.json`;

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        setUsuarioCookie(client);
        navigate(`/empresas-clientes/${client.id}`);
      } catch (error) {
        let errorResponse: string = "Error";
        if (axios.isAxiosError(error)) {
          errorResponse = error.response?.data.error || "Error";
        }
        showNotification({
          color: "red",
          message: errorResponse,
        });
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onBorrarClick = (row: Contract) => {
    openConfirmModal({
      title: "Borrar contratación",
      labels: {
        cancel: "Cancelar",
        confirm: "Borrar",
      },
      onConfirm: async () => {
        if (row.contract_type === "plan") {
          await adminService.planContracts.delete(row.id);
        } else {
          await adminService.singleUseServicesContracts.delete(row.id);
        }
        setContractTableData(null!);
        const response = await adminService.contracts.get(activePage, pageSize);
        setContractTableData(response.data);
      },
    });
  };

  const onEditClick = (row: Contract) => {
    setAddOrEdit("edit");
    setContratoAEditar(row);
    setActiveUser(row.client.id);
    openContract();
  };

  const formatDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year.length === 2 ? `20${year}` : year}`;
  };

  return (
    <GenderLabAppShell>
      <GenderLabBreadcrumbs
        breadcrumbs={[{ link: "", title: "Empresas Clientes" }]}
      />

      <>
        <Modal
          opened={addOrgOpened}
          onClose={closeOrg}
          title="Añadir empresa cliente"
        >
          <Flex direction="column" gap="md">
            <Title order={5}>Información de la organización</Title>
            <TextInput
              label="Razón social"
              {...form.getInputProps("informacionDeLaOrganizacion.razonSocial")}
            />
            <TextInput
              label="Nombre comercial"
              {...form.getInputProps(
                "informacionDeLaOrganizacion.nombreComercial"
              )}
            />
            <Title order={5}>Información de contacto</Title>
            <TextInput
              label="Nombres"
              {...form.getInputProps("informacionDeContacto.nombres")}
            />
            <TextInput
              label="Apellidos"
              {...form.getInputProps("informacionDeContacto.apellidos")}
            />
            <TextInput
              label="Correo electrónico"
              {...form.getInputProps("informacionDeContacto.correo")}
            />
            <Radio.Group
              label="¿La organización pertenece a algún grupo económico?"
              {...form.getInputProps(
                "informacionDeLaOrganizacion.perteneceAGrupoEconomico"
              )}
              onChange={(value) =>
                onChangeCleanFields(
                  value,
                  "informacionDeLaOrganizacion.perteneceAGrupoEconomico",
                  ["informacionDeLaOrganizacion.grupoEconomico"],
                  "no",
                  form
                )
              }
            >
              <Radio value="si" label="Sí" />
              <Radio value="no" label="No" />
            </Radio.Group>
            {form.values.informacionDeLaOrganizacion
              .perteneceAGrupoEconomico === "si" && (
              <TextInput
                disabled={
                  form.values.informacionDeLaOrganizacion
                    .perteneceAGrupoEconomico !== "si"
                }
                label="Nombre del grupo económico"
                {...form.getInputProps(
                  "informacionDeLaOrganizacion.grupoEconomico"
                )}
              />
            )}
            <Button disabled={loading} onClick={postUser}>
              Añadir empresa cliente
            </Button>
          </Flex>
        </Modal>

        <ModalCreateContract
          addOrEdit={addOrEdit}
          addContractOpened={addContractOpened}
          closeContract={closeContract}
          clientId={activeUser}
          callback={addOrEditContractCallback}
          contract={contratoAEditar}
        />

        <Tabs value={selectedTab} onTabChange={setSelectedTab}>
          <Tabs.List>
            <Tabs.Tab value="relacion-de-clientes">
              Relación de clientes
            </Tabs.Tab>
            <Tabs.Tab value="seguimiento-a-contrataciones">
              Relación de contrataciones
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="relacion-de-clientes" pt="xs">
            {!users ? (
              <GenderLabPageSkeleton />
            ) : (
              <>
                <Flex gap="md">
                  <TextInput
                    w={400}
                    placeholder="Buscar por nombre comercial"
                    value={clientesQuery}
                    onChange={(e) => setClientesQuery(e.target.value)}
                  />
                  <Button color="green" variant="outline" onClick={openOrg}>
                    + empresa cliente
                  </Button>
                </Flex>
                <Table
                  striped
                  highlightOnHover
                  withBorder
                  withColumnBorders
                  mt="md"
                >
                  <thead>
                    <tr>
                      <th>Razón Social</th>
                      <th>Nombre Comercial</th>
                      <th>Nombre de Contacto</th>
                      <th>Correo de Contacto</th>
                      <th>Grupo Económico</th>
                      <th />
                      <th>Contratación</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users
                      .filter((user) =>
                        user.trade_name
                          .toLowerCase()
                          .includes(clientesQuery.toLowerCase())
                      )
                      .map((user, idx) => (
                        <tr key={idx}>
                          <td>{user.business_name}</td>
                          <td>{user.trade_name}</td>
                          <td>
                            {user.contact_name} {user.contact_lastname}
                          </td>
                          <td>{user.contact_email}</td>
                          <td>{user.economic_group_name || "-"}</td>
                          <td>
                            <Button
                              color="blue"
                              variant="outline"
                              onClick={() => {
                                setUsuarioCookie({
                                  id: user.id,
                                  business_name: user.business_name,
                                  trade_name: user.trade_name,
                                  contact_name: user.contact_name,
                                  contact_lastname: user.contact_lastname,
                                  contact_email: user.contact_email,
                                  is_in_economic_group:
                                    user.is_in_economic_group,
                                  economic_group_name: user.economic_group_name,
                                });
                                navigate(`/empresas-clientes/${user.id}`);
                              }}
                              size="xs"
                            >
                              Ver
                            </Button>
                          </td>
                          <td>
                            <Flex justify="center">
                              <Button
                                color="green"
                                variant="outline"
                                size="xs"
                                onClick={() => {
                                  setActiveUser(user.id);
                                  setAddOrEdit("add");
                                  setContratoAEditar(null);
                                  openContract();
                                }}
                              >
                                +
                              </Button>
                            </Flex>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </>
            )}
          </Tabs.Panel>

          <Tabs.Panel value="seguimiento-a-contrataciones" pt="xs">
            <TextInput
              mb="sm"
              w={400}
              placeholder="Buscar por nombre de cliente o recurso"
              value={contratacionesQuery}
              onChange={(e) => setContratacionesQuery(e.target.value)}
            />
            {!contractTableData ? (
              <GenderLabPageSkeleton />
            ) : (
              <Flex direction="column" gap="sm">
                <Table highlightOnHover withColumnBorders>
                  <thead>
                    <tr>
                      <th style={{ width: "15%" }}>Cliente</th>
                      <th style={{ width: "5%" }}>País</th>
                      <th style={{ width: "17.5%" }}>Sector</th>
                      <th style={{ width: "10%" }}>Producto</th>
                      <th style={{ width: "12.5%" }}>Subproducto</th>
                      <th style={{ width: "5%" }}>Monto facturado</th>
                      <th style={{ width: "10%" }}>Tipo de contratación</th>
                      <th style={{ width: "5%" }}>Fecha de contratación</th>
                      <th style={{ width: "5%" }}>Inicio del servicio</th>
                      <th style={{ width: "5%" }}>Fín del servicio</th>
                      <th style={{ width: "5%" }}>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contractTableData.data.map((row, idx) => (
                      <tr key={idx}>
                        <td>{row.client.trade_name}</td>
                        <td>
                          <Image
                            src={
                              row.client.country?.flag_url ||
                              "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Flag_of_the_United_Nations.svg/800px-Flag_of_the_United_Nations.svg.png"
                            }
                            alt="Bandera"
                            width={25}
                          />
                        </td>
                        <td>{row.client.economic_sector?.name || "-"}</td>
                        <td>
                          {row.plan
                            ? row.plan.category
                            : row.single_use_service?.category}
                        </td>
                        <td>
                          {row.plan
                            ? row.plan.name
                            : row.single_use_service?.name}
                        </td>
                        <td>{`$${row.amount_paid}`}</td>
                        <td>
                          {row.category === "Nueva contratación" ? (
                            <Badge color="teal">Nueva contratación</Badge>
                          ) : (
                            <Badge color="orange">Renovación</Badge>
                          )}
                        </td>
                        <td>
                          <Badge color="blue">
                            {formatDate(row.created_at)}
                          </Badge>
                        </td>
                        <td>
                          <Badge color="green">
                            {row.plan ? formatDate(row.start_date) : "N/A"}
                          </Badge>
                        </td>
                        <td>
                          <Badge color="red">
                            {row.plan ? formatDate(row.end_date) : "N/A"}
                          </Badge>
                        </td>
                        <td>
                          <EditDeleteButtonsCombo
                            onEditarClick={() => onEditClick(row)}
                            onBorrarClick={() => onBorrarClick(row)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Center>
                  <Pagination
                    mt="md"
                    total={contractTableData.total_pages}
                    onChange={setPage}
                    color="blue"
                  />
                </Center>
              </Flex>
            )}
          </Tabs.Panel>
        </Tabs>
      </>
    </GenderLabAppShell>
  );
};
