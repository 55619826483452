import { Button, Flex, Modal, TextInput, Text, Progress } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { TStaffSurvey } from "../../../types";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { GenderLabPageSkeleton } from "../../GenderLabPageSkeleton";
import { adminService } from "../../../api";
import { useForm } from "@mantine/form";

type Props = {
  editStaffSurveyOpened: boolean;
  closeStaffSurvey: () => void;
  callback: () => void;
  staffSurvey: TStaffSurvey | null;
};

export const ModalEditStaffSurvey = ({
  editStaffSurveyOpened,
  closeStaffSurvey,
  callback,
  staffSurvey,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!staffSurveyForm.validate().hasErrors && staffSurvey) {
      setLoading(true);
      const endDateFormatted = dayjs(staffSurveyForm.values.endDate).format("YYYY-MM-DD");
      adminService.staffSurvey.put(staffSurvey.id, {
        ended_at: endDateFormatted,
      })
      .then(() => {
        callback();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
    } else {
      console.log(staffSurveyForm.errors);
    }
  };

  const getColor = (progress: number): string => {
    if (progress <= 0.3) {
      return "red";
    }
    else if (progress <= 0.99) {
      return "yellow";
    }
    return "teal";
  };

  const minDate = staffSurvey ? dayjs(staffSurvey.launched_at).toDate() : undefined;
  const maxDate = minDate
    ? dayjs(minDate).add(60, "days").toDate()
    : undefined;

  const percentMinimumSampleProgress = staffSurvey ? staffSurvey.minimum_sample_progress * 100 : 0;
  const percentCompletedTotalPopulationProgress = staffSurvey ? staffSurvey.total_population_progress * 100 : 0;

  useEffect((): void => {
    if (staffSurvey) {
      staffSurveyForm.setValues({
        endDate: dayjs(staffSurvey.ended_at).toDate(),
      });
    }
  }, [staffSurvey]);

  
  const staffSurveyForm = useForm({
    initialValues: {
      endDate: staffSurvey ? dayjs(staffSurvey.ended_at).toDate() : null,
    },
    validate: {
      endDate: (value) => value === null && "Campo requerido",
    },
  });

  return (
    <Modal
      size="lg"
      opened={editStaffSurveyOpened}
      onClose={closeStaffSurvey}
      title="Editar encuesta al personal"
    >
      {!staffSurvey ? (
        <GenderLabPageSkeleton />
      ) : (
        <form onSubmit={handleSubmit}>
          <Flex gap="sm" direction="column">
            <TextInput
              label="Fecha de lanzamiento"
              value={staffSurvey ? dayjs(staffSurvey.launched_at).format("DD/MM/YYYY") : ""}
              disabled
              readOnly
            />
            <DatePicker
              label="Fecha de finalización"
              minDate={minDate}
              maxDate={maxDate}
              inputFormat="DD/MM/YYYY"
              initialMonth={minDate}
              {...staffSurveyForm.getInputProps("endDate")}
            />
            <Flex gap="xs" direction={"column"}>
              <Text size="sm" fw={500}>
                Progreso de la muestra mínima
              </Text>
              <Progress
                label={percentMinimumSampleProgress.toString() + "%"}
                value={percentMinimumSampleProgress < 10 ? 10 : percentMinimumSampleProgress}
                color={getColor(staffSurvey.minimum_sample_progress)}
                radius="xl"
                size={18}
              />
            </Flex>
            <Flex gap="xs" direction={"column"}>
              <Text size="sm" fw={500}>
                Progreso de la población total
              </Text>
              <Progress
                label={percentCompletedTotalPopulationProgress.toString() + "%"}
                value={percentCompletedTotalPopulationProgress < 10 ? 10 : percentCompletedTotalPopulationProgress}
                color={"blue"}
                radius="xl"
                size={18}
              />
            </Flex>
            <Flex mt="lg" justify="end">
              <Button disabled={loading} type="submit">
                Guardar cambios
              </Button>
            </Flex>
          </Flex>
        </form>
      )}
    </Modal>
  );
};
