import React from "react";
import { UnstyledButton, Group, Text, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router";
import { RiHome2Line, RiSurveyLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { TbSchool } from "react-icons/tb";
import { SiGoogleclassroom } from "react-icons/si";

interface MainLinkProps {
  icon: React.ReactNode;
  label: string;
  route: string;
}

function MainLink({ icon, label, route }: MainLinkProps) {
  const navigate = useNavigate();
  return (
    <UnstyledButton
      onClick={() => navigate(route)}
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.sm,
        borderRadius: theme.radius.sm,
        color: theme.black,

        "&:hover": {
          backgroundColor: theme.colors.gray[0],
        },
      })}
    >
      <Group>
        {icon}
        <Text size="sm" fw={400}>
          {label}
        </Text>
      </Group>
    </UnstyledButton>
  );
}

export function MainLinks() {
  const theme = useMantineTheme();
  const settings = {
    size: 20,
    color: theme.colors["genderlab-red"][5],
  };

  const data = [
    {
      icon: <RiHome2Line size={settings.size} color={settings.color} />,
      label: "Inicio",
      route: "/inicio",
    },
    {
      icon: <FiUsers size={settings.size} color={settings.color} />,
      label: "Empresas Clientes",
      route: "/empresas-clientes",
    },
    {
      icon: <RiSurveyLine size={settings.size} color={settings.color} />,
      label: "Mediciones",
      route: "/mediciones",
    },
    // {
    //   icon: <GoChecklist size={settings.size} color={settings.color} />,
    //   label: "Contrataciones",
    //   route: "/contrataciones",
    // },
    {
      icon: <TbSchool size={settings.size} color={settings.color} />,
      label: "Aula Virtual",
      route: "/aula",
    },
    {
      icon: <SiGoogleclassroom size={settings.size} color={settings.color} />,
      label: "Webinars",
      route: "/webinars",
    },
  ];

  const links = data.map((link) => <MainLink {...link} key={link.label} />);
  return <div>{links}</div>;
}
