import { Flex } from "@mantine/core";
import { DeleteButton } from "./DeleteButton";
import { EditButton } from "./EditButton";

type Props = {
  onEditarClick: () => void;
  onBorrarClick: () => void;
  disabled?: boolean;
};
export const EditDeleteButtonsCombo = ({
  onEditarClick,
  onBorrarClick,
  disabled,
}: Props) => {
  return (
    <Flex gap="3px">
      <EditButton onClick={onEditarClick} disabled={disabled} />
      <DeleteButton onClick={onBorrarClick} disabled={disabled} />
    </Flex>
  );
};
