import { createStyles } from "@mantine/core";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";

type Props = {
  to: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  fontSize?: string | number;
  target?: string;
};

interface MyComponentStyles extends CSSProperties {
  fontSize?: string | number;
}

const useStyles = createStyles((theme, { fontSize }: MyComponentStyles) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    color: "#1c7ed6",
    fontSize: fontSize,
    fontWeight: 400,
  },
  disabledLink: {
    "&:hover": {
      textDecoration: "none",
      cursor: "not-allowed",
    },
    fontSize: fontSize,
    fontWeight: 400,
    color: "#a0a0a0",
  },
}));

export const CustomLink = ({
  to,
  children,
  disabled,
  onClick,
  fontSize = "14px",
  target,
}: Props) => {
  const { classes } = useStyles({ fontSize });
  return (
    <>
      {disabled ? (
        <span className={classes.disabledLink}>{children}</span>
      ) : (
        <Link
          to={to}
          className={classes.link}
          onClick={onClick}
          target={target || "_self"}
        >
          {children}
        </Link>
      )}
    </>
  );
};
