import { useState, useEffect } from "react";
import { Modal, Select } from "@mantine/core";
import ElsaPlatformForm from "./PlatformForm";
import AulaVirtualForm from "./AulaVirtualForm";
import OtherProductForm from "./OtherProductForm";
import { Contract, Plan, Resource, SingleUseService } from "../../../types";
import { adminService } from "../../../api";
import { GenderLabPageSkeleton } from "../../GenderLabPageSkeleton";

type Props = {
  addOrEdit: "add" | "edit";
  addContractOpened: boolean;
  closeContract: () => void;
  clientId: number;
  callback: () => void;
  contract: Contract | null;
};

export const ModalCreateContract = ({
  addOrEdit,
  addContractOpened,
  closeContract,
  clientId,
  callback,
  contract,
}: Props) => {
  const [product, setProduct] = useState<string | null>("ELSA Plataforma");
  const [platformPlans, setPlatformPlans] = useState<Plan[]>([]);
  const [aulaVirtualPlans, setAulaVirtualPlans] = useState<Plan[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);
  const [singleUseServices, setSingleUseServices] = useState<
    SingleUseService[]
  >([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (contract) {
      if (contract.contract_type === "plan") {
        setProduct(contract.plan?.category || "ELSA Plataforma");
      } else {
        setProduct("Otros");
      }
    } else {
      setProduct("ELSA Plataforma");
    }
  }, [contract]);

  useEffect(() => {
    const getResources = async () => {
      const response = await adminService.resources.get();
      setResources(response.data);
    };

    const getPlans = async () => {
      const response = await adminService.plans.get();
      const customPlan = {
        id: -1,
        name: "Plan Custom",
        resources: [],
      };
      setPlatformPlans([
        ...response.data.filter(
          (plan: Plan) => plan.category === "ELSA Plataforma"
        ),
        customPlan,
      ]);
      setAulaVirtualPlans(
        response.data.filter(
          (plan: Plan) => plan.category === "ELSA Aula Virtual"
        )
      );
    };
    const getSingleUseServices = async () => {
      const response = await adminService.singleUseServices.get();
      setSingleUseServices(response.data);
    };

    getResources();
    getPlans();
    getSingleUseServices();
  }, []);

  return (
    <Modal
      size="lg"
      opened={addContractOpened}
      onClose={closeContract}
      title="Añadir contratación"
    >
      {!platformPlans.length ||
      !aulaVirtualPlans.length ||
      !resources.length ? (
        <GenderLabPageSkeleton />
      ) : (
        <>
          <Select
            label="Producto"
            data={["ELSA Plataforma", "ELSA Aula Virtual", "Otros"]}
            value={product}
            onChange={(value) => setProduct(value)}
            sx={{ marginBottom: "15px" }}
            disabled={addOrEdit === "edit"}
          />
          {product === "ELSA Plataforma" && (
            <ElsaPlatformForm
              loading={loading}
              setLoading={setLoading}
              platformPlans={platformPlans}
              resources={resources}
              callback={callback}
              clientId={clientId}
              contract={contract}
              addOrEdit={addOrEdit}
            />
          )}
          {product === "ELSA Aula Virtual" && (
            <AulaVirtualForm
              loading={loading}
              setLoading={setLoading}
              aulaVirtualPlans={aulaVirtualPlans}
              callback={callback}
              clientId={clientId}
              contract={contract}
              addOrEdit={addOrEdit}
            />
          )}
          {product === "Otros" && (
            <OtherProductForm
              loading={loading}
              setLoading={setLoading}
              singleUseServices={singleUseServices}
              setSingleUseServices={setSingleUseServices}
              callback={callback}
              clientId={clientId}
              contract={contract}
              addOrEdit={addOrEdit}
            />
          )}
        </>
      )}
    </Modal>
  );
};
