import { Route, Routes } from "react-router-dom";

import {
  LoginPage,
  InicioPage,
  NotFoundPage,
  EmpresasClientesPage,
  EmpresaClientePage,
  AulaAdminPage,
  WebinarsPage,
  MedicionesPage,
} from "./pages";
import { ProtectedRoute } from "./utils/ProtectedRoute";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />}></Route>
      <Route
        path="inicio"
        element={<ProtectedRoute children={<InicioPage />} />}
      />
      <Route path="empresas-clientes">
        <Route
          index
          element={<ProtectedRoute children={<EmpresasClientesPage />} />}
        />
        <Route
          path=":id"
          element={<ProtectedRoute children={<EmpresaClientePage />} />}
        />
      </Route>
      <Route
        path="aula"
        element={<ProtectedRoute children={<AulaAdminPage />} />}
      />
      <Route path="webinars">
        <Route index element={<ProtectedRoute children={<WebinarsPage />} />} />
      </Route>
      <Route path="mediciones">
        <Route index element={<ProtectedRoute children={<MedicionesPage />} />} />
      </Route>
      <Route
        path="*"
        element={<ProtectedRoute children={<NotFoundPage />} />}
      />
    </Routes>
  );
}
